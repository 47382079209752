import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

import logoIcon from '../../resources/images/logos/lensesio-logo-icon-b-vsmall.png'

class businessContinuity extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        {/* <!-- ========== Business Continuity & Incident Management ========== --> */}
        <section className="mt-5 py-5 px-2">
          <div className="container-1">
            <div className="row pt-4">
              {/* Legals / Company Name / Address */}
              <div className="col-md-12 pb-5">
                <table style={{ width: '100%' }} className="f-14 mb-5">
                  <tr>
                    <td align="left" className="text-align-right">
                      <b>Lenses.io Ltd</b> is a company registered in England &
                      Wales
                      <br />
                      Company Registered Number 09975716
                      <br />
                      VAT number: GB 231980705
                      <br />
                      Registered office address
                      <br />
                      C/O Corporation Service Company (Uk) Limited, 5 Churchill
                      Place, 10th Floor,
                      <br />
                      London, E14 5HU, United Kingdom
                    </td>
                  </tr>
                </table>
              </div>

              {/* Purpose of document */}
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={logoIcon}
                    alt="Lenses.io logo icon"
                    className="pb-5"
                  />
                  <br />
                  <br />
                  <br />
                  <p className="f-26 fw-600 roboto pb-5">
                    <span className="primary-text">Lenses ®</span>
                    <span> Enterprise</span>
                    <br />
                    <br />
                    Business Continuity & Incident Management
                  </p>
                </div>
                <br />

                {/* Executive Summary */}
                <p>
                  The following document outlines policies and plans around
                  disaster and impact analysis, business continuity and incident
                  management that Lenses.io is following, to ensure a high level
                  or reliability and security when delivering services to
                  customers including Government, Financial, Insurance and
                  Healthcare organizations.
                </p>

                {/* Definitions */}
                <p>
                  <b>1. DEFINITIONS</b>
                </p>
                <p>
                  “Probability rating” is defined as Very Low, Low, Medium,
                  High, Very High.
                </p>
                <p>
                  “Impact rating” is defined as Negligible, Significant, Major,
                  Critical, Catastrophic.
                </p>

                {/* Disaster Recovery */}
                <p>
                  <b>2. DISASTER RECOVERY</b>
                </p>

                <p>
                  The purpose of Disaster Recovery is to identify issues that
                  can impact the business, their probability and planning around
                  minimizing such risks. The main risks identified and listed in
                  the following table are related to Loss of data (no
                  organization is immune to Loss of Data) and unexpected/natural
                  disasters.
                </p>

                <table className="table">
                  <thead>
                    <tr className="border-pricing-team">
                      <th
                        className="border-right border-pricing-team pt-3 pl-4 footer-color"
                        scope="col"
                      >
                        Risk
                      </th>
                      <th
                        className="border-right border-pricing-team pt-3 pl-4 footer-color"
                        scope="col"
                      >
                        Assessment
                      </th>
                      <th
                        className="border-right border-pricing-team pt-3 pl-4 footer-color"
                        scope="col"
                      >
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td scope="col" className="pl-4 border-right">
                        Loss of Data
                      </td>
                      <td className="pl-4 border-right">
                        <i>&ldquo;Low&rdquo;</i> probability{' '}
                        <i>
                          {' '}
                          <br />
                          <br />
                          &ldquo;Significant&rdquo;
                        </i>{' '}
                        impact
                      </td>
                      <td className="pl-4">
                        The probability is <i>&ldquo;low&rdquo; </i> due to the
                        nature of the product and the fact that the company
                        operates on a zero-customer data policy (Lenses does not
                        store, process or access any customer data). The
                        internal company data that are at risk of Loss are 1)
                        Intellectual Property related (such as source code) 2)
                        accounting, taxation and financial data 3) HR data 4)
                        support data 5) marketing and sales data as required per
                        normal business operations.
                        <br />
                        <br />
                        1) Internal IP related data (source code) are stored in
                        an immutable, distributed code-repository with secondary
                        fail-safe mechanisms including external escrow services
                        (
                        <a href="../software-security-certification/">
                          details
                        </a>
                        ).
                        <br />
                        <br />
                        2) Internal Accounting, taxation and financial data are
                        stored in global Cloud services (QuickBooks, ADP, Xero)
                        with whom the company has DPA agreements in place, with
                        a secondary fail-safe mechanism of backup data held by
                        our chartered accountants.
                        <br />
                        <br />
                        3) Internal HR data, are stored in global Cloud services
                        (BambooHR) with whom the company has a DPA agreement in
                        place and stored in accordance to our data privacy
                        policy.
                        <br />
                        <br />
                        4) Support data, are stored in global Cloud services
                        (ZenDesk) with whom the company has a DPA agreement in
                        place.
                        <br />
                        <br />
                        5) Internal data related to Sales and Marketing, are
                        stored in global Cloud services (Salesforce) with whom
                        the company has a DPA agreement in place and data is
                        stored and accessed in accordance to our data privacy
                        policy.
                        <br />
                        <br />
                        All such data are handled in accordance to GDPR and UK
                        Data Privacy Policies and as documented publicly in our{' '}
                        <a href="https://lenses.io/legals/data-privacy/">
                          data privacy policy
                        </a>
                        .
                      </td>
                    </tr>
                    <tr>
                      <td scope="col" className="pl-4 border-right">
                        Unexpected / Natural Disaster
                      </td>
                      <td className="pl-4 border-right">
                        &ldquo;High&rdquo; probability
                        <br />
                        <br />
                        &ldquo;Negligible&rdquo; impact
                      </td>
                      <td className="pl-4">
                        The probability is &ldquo;high&rdquo; due to the
                        numerous incidents that can be classified as an
                        unexpected or natural disaster: loss of electricity,
                        loss of internet connectivity, an office related
                        incident, a strike or a pandemic are all possible
                        incidents for the company.
                        <br />
                        <br />
                        The impact is &ldquo;negligible&rdquo; as the company
                        operates from multiple locations and countries in Europe
                        and North America. To ensure the offering of world class
                        support, in accordance to our
                        <a href="../sla/">SLAs </a> and avoid the risk of this
                        disaster to be elevated to &ldquo;Major&rdquo;, the
                        company has a policy on support, where members of the
                        support team are daily in 3 (or more) different
                        geographic locations, and any unresponded tickets are
                        escalated across the entire engineering organization.
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Insurances */}
                <p>
                  <b>3. INSURANCES</b>
                </p>
                <p>
                  The company is holding insurance policies at sufficient
                  levels, from &lsquo;A&rsquo; rated by A.M. Best, Standard &
                  Poor&apos;s and &lsquo;A+&rsquo; by Fitch companies in the UK
                  and USA, including:
                  <br />
                  <br />
                  - Employers liability (£10,000,000)
                  <br />
                  - Public and products liability (£5,000,000)
                  <br />
                  - Management liability - Director&apos;s and Officers&apos;
                  liability (£2,000,000)
                  <br />
                  - Professional indemnity (£1,000,000)
                  <br />
                  <br />
                  As well as additional security policies for:
                  <br />
                  <br />
                  - Cyber and data
                  <br />
                  - Crisis Containment <br />
                  - Reputation protection
                  <br />
                  - Key person cover
                  <br />
                  - Network security and personal data events
                  <br />
                  - Directors&apos; personal cyber
                  <br />- Terrorism
                </p>

                {/* Operational Risks */}
                <p>
                  <b>4. OPERATIONAL RISKS</b>
                </p>
                <p>
                  Lenses.io periodically reviews and refreshes it policies to
                  identify any operational risk across our product, services,
                  activities, processes, people and systems that can impact the
                  company&apos;s business continuity or impact our customers or
                  their reputation. The key policies in place are:
                </p>
                <p>
                  - Product teams and engineering following our Software
                  Security Certification process available at
                  https://lenses.io/legals/software-security-certification/
                  <br />
                  - All Lenses.io employees having an NDA in place, and
                  following our Data Privacy policy available at
                  https://lenses.io/legals/data-privacy/
                  <br />
                  - Regular assessments to identify where risks may not be
                  sufficiently mitigated
                  <br />
                  - Regular review and maintenance of risk records
                  <br />
                  - Controls to ensuring continual compliance with SLAs with our
                  clients via automated reporting in our customer support
                  solution.
                  <br />
                  - Identifying and assessing the key controls to mitigate risks
                  <br />
                  - At least annually testing of the effectiveness of the key
                  controls
                  <br />
                  - Identifying and escalating material operational risk events
                  <br />
                  - Advise clients of an event / incident within 24 hours and
                  keep audit log of all incidents
                  <br />
                  - Root Cause Analysis, mitigating similar issues in future and
                  reporting of outputs
                  <br />- Apply a baseline set of minimum controls that need to
                  be configured on End User Computing Application&apos;s
                  (EUCA&apos;s), to ensure adequate governance over its use and
                  protection of the data that is contained within
                </p>

                {/* Incident Management */}
                <p>
                  <b>5. INCIDENT MANAGEMENT</b>
                </p>
                <p>
                  The company policy, once an incident has been detected, is to
                  access the impact and severity, escalate to stakeholders and
                  if an external entity (customer or partner) is affected, to
                  immediately communicate to all third parties with transparency
                  and full disclosure. A list of all i) notifications ii)
                  advisories and iii) incidents is available bellow.
                  <br />
                </p>

                <p>
                  <pre>
                    <i>Advisory</i> <b>19-Jul-2019</b> Lenses (SLE-SA-2711) -
                    Application Vulnerability Assessment highlighted 2 medium
                    and 3 low severity findings. It is recommended to upgrade to
                    Lenses version 2.3.6 or later;
                    <br />
                    <br />
                    <i>Advisory</i> <b>31-Aug-2020</b> An issue with a
                    third-party library was identified on Friday, 28th Aug 2020
                    affecting the performance and stability of Lenses version
                    4.0.1. A new release was made available on Monday, 31st Aug
                    2020;
                    <br />
                    <br />
                    <i>Notification</i> <b>14-Jan-2021</b> Lenses support is now
                    using ZenDesk. All open tickets have been migrated, and all
                    customers notified. No interruption of services or changes
                    are required;
                    <br />
                    <br />
                    <i>Advisory</i> <b>22-Feb-2021</b> If using Lenses 4.1
                    series, please make sure you upgrade to the latest 4.1.3
                    version of Lenses. A regression in the 4.1 series caused
                    performance degradation, and 4.1.3 also fixes an issue that
                    occasionally a Connect worker could be restarted.
                    <br />
                    <br />
                    <i>Advisory</i> <b>25-Nov-2024</b> Customers on Lenses 5.5.x
                    should upgrade to Lenses 5.5.13 or later due to a
                    security defect.
                    <br />
                    <br />
                  </pre>
                </p>

                <p>
                  <b>6. REFERENCES</b>
                </p>
                <ul className="list-style-none2">
                  <li>
                    <a
                      href="../data-privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lenses.io Data Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="../software-security-certification/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lenses.io Software Security policy
                    </a>
                  </li>
                  <li>
                    <a href="../sla/">Lenses.io Service Level Agreement</a>
                  </li>
                </ul>
                <hr className="pt-2" />
                <p className="pt-2">
                  Last update: 11 Oct 2021
                  <br />
                  <br />
                  <small>
                    {' '}
                    The Business Continuity & Incident Management document may
                    be updated from time to time.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default businessContinuity

export const pageQuery = graphql`
  query legalsbusinessContinuitySeo {
    allContentfulPages(
      filter: {
        pageReference: { pageIdentifier: { eq: "LEGALS_Business_Continuity" } }
      }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
